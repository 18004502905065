import React from 'react'
import ReactDOM from 'react-dom';
import Header from './Header'
import Footer from './Footer'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { MyDocument } from './PdfGenerator';
import { Calendar } from 'primereact/calendar';
import { PDFDownloadLink, Document, Page, PDFViewer, Text, View, StyleSheet } from '@react-pdf/renderer'
import { Dialog } from 'primereact/dialog';
import Moment from 'moment';
import CustomerSearch from './CustomerSearch'
import Search from '../Common/Search'
import { Checkbox } from 'primereact/checkbox';
import Loading from '../Loading'
import Message from '../Common/Message'
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { APIUrl } from "../Common/Api";
let sum = 0.00;
let grandTotal = 0.00;
let userData = "";
let bankDetails = [];
class reciept extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ReceiptArray: [],
            pdfData: '',
            ReceiptMasterID: '',
            receiptDate: new Date(),
            total: 0.00,
            grandTotal: 0.00,
            advance: 0.00,
            discount: 0.00,
            visible: false,
            searchVisible: false,
            customerVisible: false,
            searchType: 0,
            customerAddress: '',
            billNo: '',
            customerId: '',
            signatureEnable: true,
            loading: false,
            messageVisible: false,
            errorMessage: '',
            messageStatus: true,
            messageHeader: '',
            messageConfirm: false,
            searchObject: { query: '', columns: [], continueFunction: '', heading: '', visible: false, onHide: '', filterValue: '' },
            autoComplete: [],
            filteredParticulars: null,
            rowIndex: '',
            CompanyList: [],
            CompanyId: '',
            logo: '',
            signature: '',
            enableCompany: false,
            bankDetails: [],
            Pancard: ''
        };
        this.handleParticulars = this.handleParticulars.bind(this);
        this.handleAmount = this.handleAmount.bind(this);
        this.myRef = React.createRef();
    }
    componentDidMount() {

        if (this.props.selectedRow != '' && this.props.selectedRow != undefined) {
            this.fillCompany()
            this.getHeaderByVoucherID(this.props.selectedRow.VoucherNo)
        } else {
            this.clearData();
        }
        // this.getAutoCompleteParticulars();
    }
    handleParticulars(props) {
        return this.inputTextEditor(props, 'Particulars');
    }
    handleCompany = (e) => {

        let logo = '';
        let signature = "";
        let Pancard = "";
        for (var i = 0; i < userData.companyList.length; i++) {
            if (userData.companyList[i].CompanyId == e.value) {

                logo = userData.companyList[i].Image;
                signature = userData.companyList[i].Image1
                Pancard = userData.companyList[i].Pancard
                break;
            }

        }

        this.setState({ CompanyId: e.value, logo: this.getImage(logo), signature: this.getImage(signature), Pancard: Pancard })
        this.getVoucherNo(e.value);
        this.getBankDetails(e.value);
    }
    handleSignature = (e) => {

        if (!e.checked) {

            this.setState({ signature: 'signature', signatureEnable: e.checked });
        } else {
            let signature = '';
            for (var i = 0; i < userData.companyList.length; i++) {
                if (userData.companyList[i].CompanyId == this.state.CompanyId) {
                    signature = userData.companyList[i].Image1
                    break;
                }

            }

            this.setState({ signature: this.getImage(signature), signatureEnable: e.checked })

        }

    }
    fillCompany = () => {
        let jsonData = this.props.loginUser.companyList
        userData = this.props.loginUser;
        let list = [];
        for (var i = 0; i < jsonData.length; i++) {
            list.push({ 'label': jsonData[i].CompanyName, 'value': jsonData[i].CompanyId })
        }
        this.setState({ CompanyList: list, logo: this.getImage(jsonData[0].Image), CompanyId: jsonData[0].CompanyId, signature: this.getImage(jsonData[0].Image1), Pancard: jsonData[0].Pancard })
        this.getBankDetails(jsonData[0].CompanyId);
    }
    getBankDetails = async (CompanyId) => {
        let url = APIUrl + 'Search/GetBankDetails'
        let data = new FormData
        data.append("CompanyId", CompanyId)
        const requestOptions = {
            method: 'POST',
            body: data

        };
        let response = await fetch(url, requestOptions)

        if (response.status == 200) {
            let jsonData = await response.json();
            if (jsonData != "") {
                bankDetails = jsonData;
            }
            //  this.setState({bankDetails : jsonData})
        }

    }
    filterParticularSingle(event) {
        setTimeout(() => {
            var results = this.state.autoComplete.filter((item) => {
                return item.particulars.toLowerCase().startsWith(event.query.toLowerCase());
            });

            this.setState({ filteredParticulars: results });


        }, 250);
    }
    handleAmount(props) {
        return this.inputAmountEditor(props, 'Amount');
    }
    inputTextEditor(props, field) {
        return (<div className="p-inputgroup"><InputText type="text" style={{ width: "100%" }} value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />
            <i id={props.rowIndex} className="pi pi-search" onClick={this.showParticulars}></i></div>)
        //<Button icon="pi pi-search" className="p-button-warning" onClick={this.showParticulars} style={{ width: "10%" }} /></div>)

        //<InputText type="text" style={{ width: "100%" }} value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
        //return (<AutoComplete field="particulars" itemTemplate={this.itemTemplate} value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)}
        //  suggestions={this.state.filteredParticulars} completeMethod={this.filterParticularSingle.bind(this)} />)

    }
    inputAmountEditor(props, field) {
        return <InputText type="text" keyfilter="pint" style={{ width: "100%" }} value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }
    formatdateforPrint = (value) => {
        if (typeof value === 'object') {
            return Moment(value).format('DD/MM/YYYY');
        }
        else {
            return value;
        }
    }
    formatdateforSave = (value) => {
        if (typeof value === 'object') {
            return Moment(value).format('MM/DD/YYYY');
        }
        else {
            return value;
        }
    }
    formatdate = (value) => {
        const formated = Moment(value, 'DD/MM/YYYY').toDate();
        return formated;

    }
    onEditorValueChange(props, value) {
        let updatedReceipt = [...props.value];
        updatedReceipt[props.rowIndex][props.field] = value;
        this.updateReceiptData(props.rowIndex, props.field, value);

    }
    updateReceiptData = (row, Field, Value) => {

        let updatedList = this.state.ReceiptArray;
        updatedList[row][Field] = Value;


        for (var i = updatedList.length - 1; i >= 0; i--) {

            if (updatedList[i].Particulars != undefined && updatedList[i].Amount != undefined) {
                if (updatedList[i]["Particulars"] === '' && updatedList[i]["Amount"] === '') {
                    updatedList.splice(i, 1);
                }
            }
        }
        sum = 0;
        for (var i = 0; i < updatedList.length; i++) {

            if (updatedList[i]["Amount"] != "") {
                sum = sum + parseInt(updatedList[i]["Amount"]);
                updatedList[i]["SI"] = i + 1
            }
        }




        if (updatedList.length > 0) {
            if (updatedList[updatedList.length - 1]["Particulars"] != '' && updatedList[updatedList.length - 1]["Amount"] != '') {
                //updatedList.push({ SI: updatedList.length + 1, Particulars: '', Amount: '' })
                updatedList.push({ SI: '', Particulars: '', Amount: '' })
            }
        }
        else {

            updatedList.push({ SI: '', Particulars: '', Amount: '' })
        }


        this.setState({ ReceiptArray: updatedList, total: sum.toFixed(2) });
        this.updateAdvance();
        this.updateDiscount();

    }
    updateAdvance = (e) => {
        if (e != undefined) {
            this.setState({ advance: e.target.value })
            let total = sum - e.target.value;
            if (this.state.discount != '') {
                total = total - parseInt(this.state.discount);
            }
            grandTotal = total

            this.setState({ grandTotal: grandTotal.toFixed(2) })

        } else {
            if (this.state.advance != '') {

                let total = sum - parseInt(this.state.advance);

                if (this.state.discount != '') {
                    total = total - sum - parseInt(this.state.discount);
                }
                grandTotal = total

            } else {

                let total = sum;

                if (this.state.discount != '') {
                    total = total - parseInt(this.state.discount);
                }


                grandTotal = total
            }
            this.setState({ grandTotal: grandTotal.toFixed(2) })


        }


    }

    updateDiscount = (e) => {
        if (e != undefined) {
            this.setState({ discount: e.target.value })

            let total = sum - e.target.value;
            if (this.state.advance != '') {
                total = total - parseInt(this.state.advance);
            }
            grandTotal = total
            this.setState({ grandTotal: grandTotal.toFixed(2) })

        } else {
            if (this.state.discount != '') {
                let total = sum - parseInt(this.state.discount);
                if (this.state.advance != '') {
                    total = total - parseInt(this.state.advance);
                }
                grandTotal = total;


            } else {
                let total = sum;
                if (this.state.advance != '') {
                    total = total - parseInt(this.state.advance);
                }

                grandTotal = total
            }
            this.setState({ grandTotal: grandTotal.toFixed(2) })


        }


    }

    showPreview = () => {

        this.setState({ visible: true })
    }
    onHide = () => {

        this.setState({ visible: false })
    }
    customerHide = () => {

        this.setState({ customerVisible: false })
    }
    showSearch = () => {
        this.setState({ customerVisible: true, searchType: 0 })

    }
    setCustomerDetails = (selectedRow) => {

        this.setState({ customerAddress: selectedRow.Address, customerId: selectedRow.id })

    }
    getVoucherNo = async (companyId) => {

        let updateState = this.state;

        let url = APIUrl + 'Receipt/GetMaxVOucherNo'
         var data = new FormData();
           data.append("CompanyId", companyId);

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {

            const jsonData = await response.json();
            if (jsonData != '') {
                this.setState({ billNo: jsonData, loading: false })
            }

        }
        else {
            this.setState({ billNo: '' })

        }
    }
    getAutoCompleteParticulars = async () => {

        let url = APIUrl + 'Receipt/GetAutocompleteParticulars'
        let response = await fetch(url)
        if (response.status == 200) {
            const jsonData = await response.json();
            if (jsonData != '') {
                this.setState({ autoComplete: jsonData, loading: false })
            }

        }

    }
    itemTemplate(brand) {
        return (
            <div className="p-clearfix">
                <div style={{ fontSize: '16px', float: 'right', margin: '10px 10px 0 0' }}>{brand}</div>
            </div>
        );
    }
    SearchHide = () => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search })
    }
    findResult = (selectedrow) => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search, loading: true })
        this.getHeaderData(selectedrow.ReceiptMasterID)

    }
    showFind = () => {

        let search = this.state.searchObject
        search.columns = [{ field: 'VoucherNo', header: 'VoucherNo', width: '25%' }, { field: 'VoucherDate', header: 'VoucherDate', width: '25%' }, { field: 'ReceiverAdd', header: 'Address', width: '50%' }, { field: 'ReceiptMasterID', header: '', width: 0 },];
        search.query = "1";
        search.heading = "Find Receipt";
        search.visible = true;
        search.onHide = this.SearchHide;
        search.continueFunction = this.findResult
        this.setState({ searchObject: search })
    }
    showParticulars = (e) => {

        let search = this.state.searchObject
        search.columns = [{ field: 'particulars', header: 'Particulars', width: '100%' }];
        search.query = "2";
        search.heading = "Find Particulars";
        search.visible = true;
        search.onHide = this.SearchHide;
        search.continueFunction = this.particularsResult
        let updatedList = this.state.ReceiptArray;
        search.filterValue = updatedList[e.target.id]['Particulars']
        this.setState({ searchObject: search, rowIndex: e.target.id })

    }
    particularsResult = (selectedrow) => {
        let search = this.state.searchObject
        search.visible = false;
        let updatedList = this.state.ReceiptArray;
        updatedList[this.state.rowIndex]['Particulars'] = selectedrow.particulars;
        this.setState({ searchObject: search, loading: false, ReceiptArray: updatedList })

        //alert(selectedrow.particulars)


    }
    getHeaderByVoucherID = async (VoucherID) => {
        let url = APIUrl + 'Search/GetHeaderBYVoucher'
        let data = new FormData
        data.append("parameter", VoucherID)
        //let data = { "parameter": VoucherID }
        const requestOptions = {
            method: 'POST',
            body: data

        };
        let response = await fetch(url, requestOptions)

        if (response.status == 200) {
            let jsonData = await response.json();
            let logo = '';
            let signature = "";

            for (var i = 0; i < userData.companyList.length; i++) {
                if (userData.companyList[i].CompanyId == jsonData[0].CompanyId) {

                    logo = userData.companyList[i].Image;
                    signature = userData.companyList[i].Image1
                    break;
                }

            }
            this.setState({
                ReceiptMasterID: jsonData[0].ReceiptMasterID,
                billNo: jsonData[0].VoucherNo,
                receiptDate: this.formatdate(jsonData[0].VoucherDate),
                customerAddress: jsonData[0].ReceiverAdd,
                advance: jsonData[0].Advance,
                discount: jsonData[0].Discount,
                loading: false,
                total: jsonData[0].Total,
                grandTotal: jsonData[0].GrandTotal,
                CompanyId: jsonData[0].CompanyId,
                enableCompany: true,
                logo: this.getImage(logo),
                signature: this.getImage(signature)
            });

            sum = jsonData[0].Total;

            this.getDetailsData(jsonData[0].ReceiptMasterID);
        }
        else {
            this.setState({ loading: false })

        }

    }
    getHeaderData = async (receiptMasterID) => {
        let url = APIUrl + 'Search/GetHeaderData'
        let data = new FormData
        data.append("parameter", receiptMasterID)
        const requestOptions = {
            method: 'POST',
            body: data

        };
        let response = await fetch(url, requestOptions)

        if (response.status == 200) {
            let jsonData = await response.json();
            //if (jsonData !== "") {
            //    jsonData = JSON.parse(jsonData);
            //}
            let logo = '';
            let signature = "";

            for (var i = 0; i < userData.companyList.length; i++) {
                if (userData.companyList[i].CompanyId == jsonData[0].CompanyId) {

                    logo = userData.companyList[i].Image;
                    signature = userData.companyList[i].Image1
                    break;
                }

            }

            this.setState({
                ReceiptMasterID: jsonData[0].ReceiptMasterID,
                billNo: jsonData[0].VoucherNo,
                receiptDate: this.formatdate(jsonData[0].VoucherDate),
                customerAddress: jsonData[0].ReceiverAdd,
                advance: jsonData[0].Advance,
                discount: jsonData[0].Discount,
                loading: false,
                total: jsonData[0].Total,
                grandTotal: jsonData[0].GrandTotal,
                CompanyId: jsonData[0].CompanyId,
                enableCompany: true,
                logo: this.getImage(logo),
                signature: this.getImage(signature)
            });
            sum = jsonData[0].Total;

            this.getDetailsData(receiptMasterID);
        }
        else {
            this.setState({ loading: false })

        }

    }
    getImage = (value) => {

        if (value) {

            return value
        }
        else {

            return "signature"
        }

    }
    getDetailsData = async (receiptMasterID) => {
        let url = APIUrl + 'Search/GetDetailData'
        let data = new FormData
        data.append("parameter", receiptMasterID)
        const requestOptions = {
            method: 'POST',
            body: data

        };
        let response = await fetch(url, requestOptions)

        if (response.status == 200) {
            let jsonData = await response.json();
            let updatedList = this.state.ReceiptArray
            if (jsonData !== '') {
                updatedList = jsonData
            }
            let length = updatedList.length
            updatedList.push({ SI: '', Particulars: '', Amount: '' })
            this.setState({ ReceiptArray: updatedList });


        }
        else {
            this.setState({ loading: false })

        }

    }
    clearData = () => {
        this.fillCompany()
        let updatedList = [];
        updatedList.push({ SI: 1, Particulars: '', Amount: '' })
        this.setState({
            ReceiptMasterID: '',
            billNo: '',
            receiptDate: this.formatdate(new Date()),
            customerAddress: '',
            total: 0.00,
            advance: 0.00,
            discount: 0.00,
            loading: false,
            grandTotal: 0.00,
            ReceiptArray: updatedList,
            loading: true,
            enableCompany: false
        });

        sum = 0.00;
        grandTotal = 0.00;
        this.getVoucherNo(this.props.loginUser.companyList[0].CompanyId);
    }
    login = () => {
        //let response = await fetch('http://192.168.1.1:8080/api/Login/Login')
        fetch('http://192.168.1.101:8080/Login/Login'
            , {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
                }
            }
        )
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidUpdate(prevProps) {
        ReactDOM.unmountComponentAtNode(document.getElementById('PDF_CONTAINER'));
        ReactDOM.render(
            <div>
                <PDFDownloadLink document={<MyDocument Pancard={this.state.Pancard} bankDetails={bankDetails} signature={this.state.signature} logo={this.state.logo} billNo={this.state.billNo} Address={this.state.customerAddress} discount={this.state.discount} advance={this.state.advance} grandTotal={this.state.grandTotal} total={this.state.total} receiptDate={this.formatdateforPrint(this.state.receiptDate)} data={this.state.ReceiptArray} />} fileName={"TalkUs_Invoice_" + this.state.billNo + ".pdf"}>
                    {({ blob, url, loading, error }) => (loading ? 'Loading....' : 'Download')}
                </PDFDownloadLink>
            </div>, document.getElementById('PDF_CONTAINER'))

        if (this.state.visible == true) {

            ReactDOM.unmountComponentAtNode(document.getElementById('pdf_Dialog'));
            ReactDOM.render(
                <div>
                    <PDFViewer style={{ width: "100%", height: "100vh" }}>{<MyDocument Pancard={this.state.Pancard} bankDetails={bankDetails} signature={this.state.signature} logo={this.state.logo} billNo={this.state.billNo} Address={this.state.customerAddress} discount={this.state.discount} advance={this.state.advance} grandTotal={this.state.grandTotal} total={this.state.total} receiptDate={this.formatdateforPrint(this.state.receiptDate)} data={this.state.ReceiptArray} />
                    }</PDFViewer>
                </div>, document.getElementById('pdf_Dialog'))
        }

    }
    requestSave = async (data) => {
        let url = APIUrl + 'Receipt/InsertReceipt'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {

            let jsonData = await response.json();
            //jsonData = JSON.parse(jsonData)

            let updatedList = this.state.ReceiptArray
            updatedList.push({ SI: '', Particulars: '', Amount: '' })
            this.setState({ enableCompany: true, ReceiptArray: updatedList, ReceiptMasterID: jsonData,messageHeader: "Message", messageStatus: true, errorMessage: "Data successfully saved", messageVisible: true, loading: false });
           // this.clearData();

        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data saving failed", messageVisible: true, loading: false });

        }
    }
    requestDelete = async (data) => {
        let url = APIUrl + 'Receipt/DeleteReceipt'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {

            this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data deleted successfully", messageVisible: true, loading: false });
            this.clearData();

        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data deletion failed !!.", messageVisible: true, loading: false });

        }
    }
    messageHide = () => {
        this.setState({ messageVisible: false })

    }
    saveReceipt = () => {


        var data = new FormData();
        let receiptData = this.state;
        let details = this.state.ReceiptArray;
        if (details.length > 0) {
            details.splice(details.length - 1, 1)
        }

        let detailArray = [];
        for (var i = 0; i < details.length; i++) {

           // detailArray.push({ "SI": details[i].SI, "Particulars": details[i].Particulars, "Amount": details[i].Amount })
            data.append("ReceiptDetails[" + i + "].SI", details[i].SI);
            data.append("ReceiptDetails[" + i + "].Particulars", details[i].Particulars);
            data.append("ReceiptDetails[" + i + "].Amount", details[i].Amount);

        }
        data.append('Id', receiptData.ReceiptMasterID)
        data.append('BillNo', receiptData.billNo)
        data.append('BillDate', this.formatdateforSave(receiptData.receiptDate))
        data.append('CustomerAdd', receiptData.customerAddress)
        data.append('CustomerID', receiptData.customerId)
        data.append('Total', receiptData.total)
        data.append('Advance', receiptData.advance)
        data.append('Discount', receiptData.discount)
        data.append('BalanceAmount', receiptData.grandTotal)
        data.append('CompanyId', receiptData.CompanyId)
       
        //let data = {
        //    'Id': receiptData.ReceiptMasterID,
        //    'BillNo': receiptData.billNo,
        //    'BillDate': this.formatdateforSave(receiptData.receiptDate),
        //    'CustomerAdd': receiptData.customerAddress,
        //    'CustomerID': receiptData.customerId,
        //    'Total': receiptData.total,
        //    'Advance': receiptData.advance,
        //    'Discount': receiptData.discount,
        //    'BalanceAmount': receiptData.grandTotal,
        //    'CompanyId': receiptData.CompanyId,
        //    'ReceiptDetails': detailArray
        //}


        this.setState({ loading: true })
        this.requestSave(data);
        return;

    }
    isValidData = () => {
        let message = '';
        let data = this.state
        if (data.customerAddress == undefined || data.customerAddress === '') {
            message = '* Customer Address not be blank!!'
        }

        if (data.grandTotal == 0.00 || data.grandTotal === 0) {
            message += '\n* Invalid billing details or billing details not be blank!!'
        }


        if (message != '') {

            this.setState({ messageVisible: true, errorMessage: message, messageStatus: false, messageHeader: 'Error' })
            return false
        }

        this.saveReceipt();
    }
    continueConfirm = () => {
        this.setState({ messageVisible: false, messageConfirm: false, loading: true })
          var data = new FormData();
        let receiptData = this.state;
         data.append('ReceiptMasterID', receiptData.ReceiptMasterID)

        this.requestDelete(data);
    }
    confirmDelete = () => {
        let receiptData = this.state;
        if (receiptData.ReceiptMasterID === '') {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "No Invoice found for deletion !!.", messageVisible: true, loading: false });
        } else {
            this.setState({ messageVisible: true, errorMessage: 'Are you sure to delete the item?.', messageStatus: true, messageHeader: 'Confirm Message', messageConfirm: true });
        }
    }
    deleteBodyTemplate = (rowData) => {
        return <i id={rowData.SI} className="pi pi-times" onClick={this.deleteReceiptRow}></i>;
    }
    deleteReceiptRow = (e) => {


        let updatedList = this.state.ReceiptArray;

        if (e.target.id != '') {
            updatedList.splice(e.target.id - 1, 1)
        } else {

            updatedList.splice(updatedList.length - 1, 1)
        }

        sum = 0;
        for (var i = 0; i < updatedList.length; i++) {

            if (updatedList[i]["Amount"] != "") {
                sum = sum + parseInt(updatedList[i]["Amount"]);
                updatedList[i]["SI"] = i + 1
            }
        }




        if (updatedList.length > 0) {
            if (updatedList[updatedList.length - 1]["Particulars"] != '' && updatedList[updatedList.length - 1]["Amount"] != '') {
                updatedList.push({ SI: '', Particulars: '', Amount: '' })
            }
        }
        else {

            updatedList.push({ SI: '', Particulars: '', Amount: '' })
        }


        this.setState({ ReceiptArray: updatedList, total: sum.toFixed(2) });
        this.updateAdvance();
        this.updateDiscount();


    }

  
    render() {
        //style={{ borderColor: "grey", borderStyle: "solid", borderWidth: "thin" }}
        var footer = (<div>

            <div className="p-grid">
                <div className="p-col-7"></div>
                <div className="p-col-2" style={{ textAlign: "left" }}>Total</div>
                <div className="p-col-3" style={{ textAlign: "left" }}>{this.state.total}</div>
            </div>

            <div className="p-grid">
                <div className="p-col-7"></div>
                <div className="p-col-2" style={{ textAlign: "left" }}>Advance</div>
                <div className="p-col-3" style={{ textAlign: "left" }}><InputText keyfilter="pint" value={this.state.advance} type="text" style={{ width: "50%" }} onChange={this.updateAdvance} /></div>
            </div>

            <div className="p-grid">
                <div className="p-col-7"></div>
                <div className="p-col-2" style={{ textAlign: "left" }}>Discount</div>
                <div className="p-col-3" style={{ textAlign: "left" }}><InputText keyfilter="pint" value={this.state.discount} type="text" style={{ width: "50%" }} onChange={this.updateDiscount} /></div>
            </div>

            <div className="p-grid">
                <div className="p-col-7"></div>
                <div className="p-col-2" style={{ textAlign: "left" }}>Grand Total</div>
                <div className="p-col-3" style={{ textAlign: "left" }}>{this.state.grandTotal}</div>
            </div>
        </div>);


        //<div className="p-grid">
        //    <div className="p-col-12"><img src={logo} /></div>

        //</div>

        //<div className="p-col-1" style={{ width: 180, textAlign: "center", }}><Checkbox onChange={e => this.setState({ csEnabled: e.checked })} checked={this.state.csEnabled}></Checkbox>CS Invoice</div>
        return (<div>

            {this.state.searchObject.visible ? <Search Company={this.props.loginUser.company} search={this.state.searchObject} /> : ''}
            {this.state.customerVisible ? <CustomerSearch CompanyId={this.state.CompanyId} Company={this.props.loginUser.company} setCustomerDetails={this.setCustomerDetails} onHide={this.customerHide} visible={this.state.customerVisible} /> : ''}
            <Dialog header="Preview" visible={this.state.visible} style={{ width: '100%', margin: 0, height:"100%" }} modal={true} onHide={this.onHide} >
                <div id="pdf_Dialog" ></div>
            </Dialog>

            <Dialog ref={this.myRef} header="Invoice" visible={this.props.visible} style={{ width: '100%', margin: 0, height: "100vh" }} modal={true} onHide={this.props.onHide} >
                {this.state.messageVisible ? <Message continue={this.continueConfirm} confirm={this.state.messageConfirm} header={this.state.messageHeader} status={this.state.messageStatus} message={this.state.errorMessage} visible={this.state.messageVisible} onHide={this.messageHide} /> : ''}
                {this.state.loading ? <Loading loading={this.state.loading} /> : ''}
                <div className="p-grid" style={{ width: "100%" }} >
                    <div className="p-col" >


                        <div className="p-grid">
                            <div className="p-col-3">
                                Invoice No:&nbsp;&nbsp;<InputText disabled value={this.state.billNo} onChange={(e) => this.setState({ billNo: e.target.value })} type="text" style={{ width: "60%" }} /></div>
                            <div className="p-col-3"></div>
                            <div className="p-col-2"></div>
                            <div className="p-col-4">Date:&nbsp;<Calendar value={this.state.receiptDate} onChange={(e) => this.setState({ receiptDate: this.formatdate(e.value) })} showIcon={true} monthNavigator={true} yearNavigator={true} yearRange="2013:2030" dateFormat="dd/mm/yy" /></div>
                        </div>
                        <div className="p-grid" style={{ verticalAlign: "top" }}>
                            <div className="p-col-1" style={{ width: 60 }}>Address</div>
                            <div className="p-col" ><InputTextarea disabled value={this.state.customerAddress} onChange={(e) => this.setState({ customerAddress: e.target.value })} rows={5} cols={60} /><i className="pi pi-search" onClick={this.showSearch}></i></div>
                            {this.props.loginUser.companyList.length > 1 ? < div className="p-col-4" ><Dropdown disabled={this.state.enableCompany} optionLabel="label" name="CompanyId" value={this.state.CompanyId} options={this.state.CompanyList} onChange={this.handleCompany} style={{ width: "100%" }} /></div> : ""}
                        </div>
                        <div>
                            <DataTable scrollable scrollHeight="250px" value={this.state.ReceiptArray} editable={true} border={true} footer={footer} responsive={true} >
                                <Column field="SI" header="SI" style={{ width: '.9em' }} />
                                <Column field="Particulars" header="Particulars" editor={this.handleParticulars} style={{ width: '3.5em' }} />
                                <Column field="Amount" header="Amount" editor={this.handleAmount} style={{ width: '1.5em' }} />
                                <Column body={this.deleteBodyTemplate} style={{ width: '.5em' }}></Column>
                            </DataTable>

                        </div>
                    </div>
                </div>
                <div className="p-grid" style={{ paddingLeft: 30, paddingTop: 5 }} >
                    <div className="p-col-1" style={{ width: 100 }}><Button type="button" icon="pi pi-check" label="Save" style={{ width: 90 }} onClick={this.isValidData} /></div>
                    <div className="p-col-1" style={{ width: 100 }}><Button type="button" label="Clear" style={{ width: 90 }} onClick={this.clearData} /></div>
                    <div className="p-col-1" style={{ width: 100 }}><Button type="button" label="Close" style={{ width: 90 }} onClick={this.props.onHide} /></div>
                    <div className="p-col-1" style={{ width: 100 }}><Button type="button" icon="pi pi-search" label="Find" style={{ width: 90 }} onClick={this.showFind} /></div>
                    <div className="p-col-1" style={{ width: 100 }}><Button type="button" icon="pi pi-times" label="Delete" style={{ width: 90 }} onClick={this.confirmDelete} /></div>
                    <div className="p-col-1" style={{ width: 100 }}><Button type="button" label="Preview" style={{ width: 90 }} type="Button" onClick={this.showPreview} /></div>
                    <div className="p-col-1" style={{ width: 100 }}><div id="PDF_CONTAINER" ></div></div>
                    <div className="p-col-1" style={{ width: 180, textAlign: "center", }}><Checkbox onChange={this.handleSignature} checked={this.state.signatureEnable}></Checkbox>show Signature</div>
                </div>
            </Dialog>
        </div>);

    }

}
export default reciept;